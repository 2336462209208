@font-face {
  font-family: 'Gibson Regular';
  src: local('Gibson-Regular'),
    url('assets/Fonts/Gibson-Regular.ttf') format('ttf');
}
@font-face {
  font-family: 'Gibson SemiBold';
  src: local('Gibson-SemiBold'),
    url('assets/Fonts/Gibson-SemiBold.ttf') format('ttf');
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Gibson Regular', 'Helvetica', 'Arial', 'Verdana', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
